<template>
    <div>
        <div class="d-flex justify-content-center w-100">
            <vs-tooltip bottom border>
                <vs-button icon circle @click="openNewFilter">
                    <img
                        src="@/assets/images/filter.svg"
                        height="15"
                        width="15"
                        alt="reload img"
                    />
                </vs-button>
                <template #tooltip> Modificar filtros de búsqueda </template>
            </vs-tooltip>
        </div>
        <div class="d-flex flex-column mt-1">
            <vs-input
                v-if="activeFilters.includes('email')"
                id="email"
                v-model="email"
                type="text"
                name="email"
                label="Correo"
                state="primary"
                class="input-theme w-100 mr-2"
                :class="!isValidEmail ? '' : 'border-custom'"
                aria-label="Email"
                icon-after
                @blur="onBlurEmail"
                @keyup.enter="onEnter"
                @click-icon="onEnter"
                @input="lowerCaseEmail"
            >
                <template #icon> ✉ </template>
                <template #message-danger>
                    <div v-show="introEmail && !isValidEmail">
                        Ingrese correo valido
                    </div>
                </template>
            </vs-input>
            <vs-input
                v-if="activeFilters.includes('identificationNumber')"
                id="document"
                v-model="identificationNumber"
                type="text"
                name="document"
                state="primary"
                label="Número de documento"
                class="input-theme w-100 mr-2 mt-3"
                aria-label="Document"
                icon-after
                @blur="onBlurIdentificationNumber"
                @keyup.enter="onEnter"
                @click-icon="onEnter"
            >
                <template #icon> 🖋 </template>
                <template #message-danger>
                    <div
                        v-show="
                            introIdentificationNumber &&
                            !isValidIdentificationNumber
                        "
                    >
                        Ingrese documento Valido
                    </div>
                </template>
            </vs-input>
        </div>

        <vs-dialog
            v-model="isOpenModal"
            scroll
            overflow-hidden
            auto-width
            @close="pushNewFilter"
        >
            <template #header>
                <div class="d-flex flex-column">
                    <div
                        class="mx-4 mt-2 d-flex flex-column flex-lg-row align-items-center"
                    >
                        <h3 class="text-center">Seleccione los filtros</h3>

                        <div>
                            <vs-button
                                :disabled="!filters.length"
                                icon
                                circle
                                @click="pushNewFilter"
                            >
                                ✔️
                            </vs-button>
                        </div>
                    </div>
                </div>
            </template>
            <div class="con-content">
                <cards-selector v-model="filters" :base="$data.$baseFilters" />
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { usersFilters } from "@/common/fields/filters-query";
import {
    emailSimpleRegex,
    identificationNumberRegex,
    nameRegex
} from "@/common/lib/regex";

import CardsSelector from "@/components/utils/CardsSelector.vue";

export default {
    name: "UsersFilters",
    components: {
        CardsSelector
    },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            default: () => ({}),
            type: Object,
            require: true
        },
        enabledFilters: {
            default: () => ["email", "identificationNumber"],
            type: Array,
            require: true
        }
    },
    data: () => ({
        activeFilters: [""],
        isOpenModal: false,
        email: "",
        phone: "",
        lastName: "",
        firstName: "",
        identificationNumber: "",
        introEmail: false,
        introPhone: false,
        introLastName: false,
        introFirstName: false,
        introIdentificationNumber: false,
        filters: [""],
        $baseFilters: usersFilters
    }),
    computed: {
        isValidEmail() {
            return emailSimpleRegex.test(this.email);
        },
        isValidIdentificationNumber() {
            return identificationNumberRegex.test(this.identificationNumber);
        },
        isValidFirstName() {
            return nameRegex.test(this.firstName);
        },
        isValidLastName() {
            return nameRegex.test(this.lastName);
        }
    },
    watch: {
        filters() {
            this.applyFilter();
        },
        enabledFilters(value) {
            this.setEnabledFilters(value);
        }
    },
    mounted() {
        this.setEnabledFilters(this.enabledFilters);
        this.applyFilter();
    },
    methods: {
        openNewFilter() {
            this.isOpenModal = true;
        },
        pushNewFilter() {
            this.activeFilters = [...this.filters];
            this.isOpenModal = false;
            this.applyFilter();
        },
        applyFilter() {
            this.$emit("change", {
                ...{
                    ...(this.activeFilters.includes("email")
                        ? { email: this.email }
                        : null)
                },
                ...{
                    ...(this.activeFilters.includes("identificationNumber") &&
                    this.identificationNumber
                        ? { identificationNumber: this.identificationNumber }
                        : null)
                }
            });
        },
        onEnter() {
            this.$emit("enter");
        },
        onBlurEmail() {
            this.introEmail = true;
            this.applyFilter();
        },
        onBlurIdentificationNumber() {
            this.introIdentificationNumber = true;
            this.applyFilter();
        },
        lowerCaseEmail() {
            this.email = this.email.toLowerCase();
        },
        setEnabledFilters(value) {
            this.baseFilters = usersFilters.filter((item) =>
                value.includes(item.id)
            );
        }
    }
};
</script>
